.about-second-page{
    width: 100vw;
    height: fit-content;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px 0px;
    padding: 60px;
}

.advantages{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: stretch;
}