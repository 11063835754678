.title-and-lines {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.title {
    text-align: center;
    color: #333;
}


.left-line,.right-line {
    width: 15%;
    height: 4px;
    background-color: #333;
    margin: 0;
}
