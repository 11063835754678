.about-first-page{
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-image: url('/media/AboutFirstPage.jpeg');   
    background-position: center center;
    background-size: 100% 100%;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 60px;
    /* align-items: center; */
    gap: 30px 0px;
}

.about-first-page-text{
    display: flex;
    flex-direction: column;
    /* gap:20px 0px; */
    text-align: right;
    align-self: flex-end;
    width: 50%;
    line-height: 2;
    /* in lower resulotions i will change it to line-height: 1.5 */
}

.about-first-page-text h3 {
    font-family: 'Aharoni CLM', sans-serif;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .about-first-page-text{
        line-height: 1.5;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .about-first-page{
        min-height: auto;
        background-image: none;
        background-color: #f4f4f4;
        align-items: center;
    }
    
    .about-first-page-text{
        width: 100%;
        text-align: center;
        align-self: center;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .about-first-page{
        min-height: auto;
        background-image: none;
        background-color: #f4f4f4;
        align-items: center;
    }
    
    .about-first-page-text{
        width: 100%;
        text-align: center;
        align-self: center;
        margin-top: 60px; /* Add margin-top to move content down */
    }
}