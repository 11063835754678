form{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    gap:40px;
    align-items: center;
    width: 60%;
    height: fit-content;
}

.contact-form div{
    display: flex;
    flex-direction: row-reverse;
    gap: 0px 10px;
    align-items: center;
}

.submit-button{
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #fff;
    background: #d8a64e;
}

.submit-button:hover {
    transition: all .1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px white;
}
