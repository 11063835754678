#navbar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* trying to do space-bwtween instead of space around so i can use padding in my pages */
    /* grid-area: nav; */
    /* background-color: rgb(20, 40, 84); */
    /* height: 90px; */
    width: 100%;
    position: relative;
    top: 0px;
    z-index: 5;
    align-items: center;
    text-align: center;
    gap: 0px 5px;
    /* הוספתי גאפ כדי שהם לא יהיו ממש צמודים לא משנה באיזה רזולוצייה */
}

#navbar li {
    list-style: none;
    font-size: 1em;
    /* padding-top: 2em; */
    /* padding-bottom: 2em; */
    /* we dont need padding because the height is 100% and the text looks good with the height of the img */
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-link{
    cursor: pointer;
    position: relative;
    text-decoration: none;
    font-weight: bold;
    padding-bottom: 5px;
    direction: rtl;
}

.nav-link::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    /* Change this color to your desired hover color */
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
}

.nav-link:hover::after{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.nav-link-img img{
    width: 200px;
    height: auto;
    vertical-align: middle;
}

@media (min-width: 481px) and (max-width: 768px) {
    .nav-link-img img{
        width: 100px;
    }
}