#vertical_navbar {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 250px;
    background-color: #333;
    height: 100dvh;
    justify-content: flex-start;
    z-index: 3;
    padding-top: 20%;
    right: -100%;
    transition: right 0.5s ease-in-out;
  }
  
  #vertical_navbar li {
    width: 100%;
    height: fit-content;
    list-style: none;
    padding: 0; /* Remove padding from li */
  }
  
  #vertical_navbar li:not(.img-li) {
    text-align: right;
  }
  
  .vertical-nav-link {
    display: block; /* Make a block-level element */
    width: 100%;
    height: 100%;
    padding: 15px 25px; /* Move padding to a */
    text-decoration: none;
    color: white;
    cursor: pointer;
    position: relative;
    font-weight: bold;
  }
  
  #vertical_navbar li:hover {
    background: #272c30;
    border-right: 3px solid #d8a64e;
  }
  
  #vertical_navbar.open {
    right: 0%;
  }
  
  .vertical-nav-link-img img {
    width: 200px;
    height: auto;
    vertical-align: middle;
  }
  
  .img-li {
    text-align: center;
    margin-top: auto;
  }
  