#hamburger_menu {
    width: 35px;
    height: 45px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    /*incremented the z-index so the hamburger menu will be above the vertical navbar */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .45s ease-in-out;
    -moz-transition: .45s ease-in-out;
    -o-transition: .45s ease-in-out;
    transition: .45s ease-in-out;
    /* transition-delay: 0.5s; */
    cursor: pointer;
}

#hamburger_menu.click{
    position: fixed;
    /* i added the fixed position so the hamburger menu can scroll with the menu itself. also, the hamburger menu should only scroll when the navbar is opened (in the X shape - .click class). in the regular shape of the hamburger menu it shouldnt scroll - position absoulute */
}

#hamburger_menu span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    transition-delay: 0.5s;
    /* wait for the vertical navbar animation */

}

#hamburger_menu.click span{
    background: white;
}

#hamburger_menu span:nth-child(1) {
    top: 0px;
}

#hamburger_menu span:nth-child(2),
#hamburger_menu span:nth-child(3) {
    top: 12px;
}

#hamburger_menu span:nth-child(4) {
    top: 24px;
}

#hamburger_menu.click span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#hamburger_menu.click span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hamburger_menu.click span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#hamburger_menu.click span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}