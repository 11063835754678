.main-second-page{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.video-container{
    width: 100%;
    height: 100%;
}

.video-container video{
    object-fit: fill;
}

@media (min-width: 481px) and (max-width: 768px) {
    .main-second-page{
        height: 40dvh;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .main-second-page{
        height: 40dvh;
    }
}