.main-first-page {
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-image: url('/media/MainFirstPage.png');
    background-position: center center;
    background-size: 100% 100%;
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 70px 0px;
}

.main-first-page-text {
    display: flex;
    flex-direction: column;
    gap: 30px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 80%;
    height: fit-content;
}

.main-first-page-text h3 {
    font-family: 'Aharoni CLM', sans-serif;
}

@media (min-width: 481px) and (max-width: 768px) {
    .main-first-page{
        min-height: 100dvh;
        background-size: cover;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .main-first-page{
        min-height: 100dvh;
        background-size: cover;
        padding: 0px 30px;
    }
    .main-first-page-text {
        margin-top: 60px; /* Add margin-top to move content down */
    }
}